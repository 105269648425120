import React, { useEffect, useRef, useState } from 'react';
import playIcon from '../assets/images/icons/The-Odd-Number-Video-Play-Icon.svg'
interface Props  {
    video: string,
    videoThumbnail: string,
    placeholder: string,
}

function extractVideoId(video: string): string | null {
    const match = video.match(/youtube\.com\/embed\/(\w+)/i);
    if (match) {
      return match[1];
    } else {
      return null;
    }
}

function VideoModal({ video, videoThumbnail, placeholder }: Props) {
    const videoId = extractVideoId(videoThumbnail);
    const videoId2 = extractVideoId(video);
    const [isOpen, setIsOpen] = useState(false);
    const [hover, setHover] = useState(false);
    const [hasClass, setHasClass] = useState(false);

    const iframeRef = useRef<HTMLDivElement>(null)

    useEffect(()=>{
      if(iframeRef.current){
        iframeRef.current.children[0].setAttribute('width', '1199')
        iframeRef.current.children[0].setAttribute('height', '600')
      }
    })
  
      
    const handleMouseEnter = () => {
        setHover(true);
    };
    
    const handleMouseLeave = () => {
        setHover(false);
    };
    
    const handleHover = () => {
        if (hasClass) {
            setHasClass(false);
        } else {
            setHasClass(true);
            if (500) {
            setTimeout(() => {
                setHasClass(false);
            }, 500);
            }
        }
    };
  return (
    <div className='video-modal'>
        <div className="thumbnail-wrapper" onClick={() => setIsOpen(true)}>
            <img className='play-icon' src={playIcon} alt="Play" />
            {videoThumbnail && <div className='short-video'><iframe src={`https://www.youtube.com/embed/${videoId}?&autoplay=1&mute=1&loop=1&playlist=${videoId}&controls=0&rel=0&showinfo=0`} title="TON Web with mute final" allow='autoplay'></iframe>
            <img src={placeholder} alt="" className="mobile-placeholder" />
            </div>}
        </div>
        {isOpen && (
        <>
            <div className="modal-overlay" onClick={() => setIsOpen(false)} >
            </div>
            <button className={`close close-btn ${hasClass ? '' : ""}`} data-animation='xMarks' data-remove={500} onMouseEnter={() => setHasClass(true)} onMouseLeave={() => setHasClass(false)}  onClick={() => setIsOpen(false)}></button>
            <div className="modal">
            {video && <div ref={iframeRef} className='modal-content'>
                <iframe src={`https://www.youtube.com/embed/${videoId2}?&autoplay=1&loop=1&playlist=${videoId2}&controls=0&rel=0&showinfo=0`} title="TON Web with mute final" allow='autoplay'></iframe>
            </div> }
            </div>
        </>
        )}
    </div>
  )
}

export default VideoModal