import React, { useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { PageData } from "../types/PageData";
import {Helmet} from 'react-helmet';

import { ReactComponent as Twitter } from '../assets/images/icons/The-Odd-Number-Twitter-Dark.svg';
import { ReactComponent as Facebook } from '../assets/images/icons/The-Odd-Number-Facebook-Dark.svg';
import { ReactComponent as Instagram } from '../assets/images/icons/The-Odd-Number-Instagram-Dark.svg';
import Navigation from '../components/Navigation';
import Heading from "../components/Heading";
import Paragraph from "../components/Paragraph";

function ContactUs() {
    const PageData = useLoaderData() as PageData;
    const items = PageData.content.no_short_codes;
    const yoast:any = PageData.yoast_head_json;

    const imgRegex = /.*src="([^"]*)".*/;
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [items]);
  return (
    <div className='contact-us'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : 'The Odd Number | '+PageData.title.rendered}</title>
        </Helmet>
        <Navigation />
        <div className="contact-banner" style={{backgroundImage: `url(${items[0][0].replace(imgRegex, '$1')})`, backgroundSize: '100% 100%'}}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-8 col-lg-7">
                        {/*{items[0][1][0] && <div dangerouslySetInnerHTML={{__html: items[0][1][0]}} />}*/}
                        {items[0][1][0] && <Heading Heading={items[0][1][0]} Tag={'h1'} />}
                        {items[0][2][1] && <Paragraph Paragraphs={items[0][2][1]} />}

                    </div>
                </div>
                <div className="row pt-0 details">
                    <div className="col-12 col-md-4">
                        <h4>Telephone:</h4>
                        {items[0][2][0] && <div dangerouslySetInnerHTML={{__html: items[0][2][0]}} />}
                    </div>
                    <div className="col-12 col-md-4">
                        <h4>Email:</h4>
                        {items[0][3][0] && <div dangerouslySetInnerHTML={{__html: items[0][3][0]}} />}
                    </div>
                    <div className="col-12 col-md-4">
                        <h4>Location:</h4>
                        {items[0][4] && <a href="https://goo.gl/maps/UvMr4XVcMTU2m6BL9" target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{__html: items[0][4]['paragraphs']}} />}
                    </div>
                </div>
                <div className="row pt-0">
                    <div className="col-12">
                        <div className="links">
                            <a className='social-icon' href="https://www.facebook.com/pages/category/Local-business/The-Odd-Number-1684367518495651/" target="_blank" rel="noreferrer"><Facebook /></a>
                            <a className='social-icon' href="https://www.instagram.com/____theoddnumber____/" target="_blank" rel="noreferrer"><Instagram /></a>
                            <a className='social-icon' href="https://twitter.com/oddnumber_?lang=en" target="_blank" rel="noreferrer"><Twitter /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ContactUs