import React, { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { PageData } from "../types/PageData";
import TextBanner from '../components/TextBanner';
import CareerAccordion from '../components/CareerAccordion';
import Cta from '../components/Cta';
import {Helmet} from 'react-helmet';
import Navigation from '../components/Navigation';

function Careers() {
    const [selectedChild, setSelectedChild] = useState('1');
    const PageData = useLoaderData() as PageData
    const items = PageData.content.no_short_codes;
    const yoast:any = PageData.yoast_head_json;
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;
    const imgRegex = /.*src="([^"]*)".*/;

    console.log(items)

    const handleClick = (childId: string) => {
        setSelectedChild(childId);
        if(selectedChild === childId) {
            setSelectedChild('');
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [items]);

  return (
    <div className='careers'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : 'The Odd Number | '+PageData.title.rendered}</title>
        </Helmet>
        <Navigation />
        <TextBanner 
            title={PageData.title.rendered}
            subtitle={items[0][0]}
            text={items[0][1][1]['paragraphs'][0]}
            background={items[0][1][0].replace(imgRegex, '$1')}
        />

        <div className="component accordion-wrapper">
            <div className="container">
                <div className="row">
                <div className="col-12">
                        <CareerAccordion 
                            id="1" onClick={handleClick} isSelected={selectedChild === '1'}
                            position={items[1][0]}
                            description={items[1][1][0]+items[1][1][3]['paragraphs']}
                            responsibilities={items[1][2][0]+items[1][2][1]['paragraphs']+items[1][3].join('')}
                            requirements={items[1][4][0]+items[1][4][1]['paragraphs']+items[1][5].join('')}
                            buttonText={items[1][1][1]}
                            buttonLink={items[1][1][1]}
                        />

                        <CareerAccordion 
                            id="2" onClick={handleClick} isSelected={selectedChild === '2'}
                            position={items[2][0]}
                            description={items[2][1][0]+items[2][1][3]['paragraphs']}
                            responsibilities={items[2][2][0]+items[2][2][1]['paragraphs']+items[2][3].join('')}
                            requirements={items[2][4][0]+items[2][4][1]['paragraphs']+items[2][5].join('')}
                            buttonText={items[2][1][1]}
                            buttonLink={items[2][1][1]}
                        />

                        <CareerAccordion 
                            id="3" onClick={handleClick} isSelected={selectedChild === '3'}
                            position={items[3][0]}
                            description={items[3][1][0]+items[3][1][3]['paragraphs']}
                            responsibilities={items[3][2][0]+items[3][2][1]['paragraphs']+items[3][3].join('')}
                            requirements={items[3][4][0]+items[3][4][1]['paragraphs']+items[3][5].join('')}
                            buttonText={items[3][1][1]}
                            buttonLink={items[3][1][1]}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Cta 
            background={items[4][0].replace(imgRegex, '$1')}
            title={items[4][1][0]}
            copy={items[4][2][2]}
            buttonHref={items[4][2][0].replace(aiButtonHref, '$1')}
            buttonText={items[4][2][0].replace(aiButtonHref, '$2')}
        />
    </div>
  )
}

export default Careers