import React, { useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { PageData } from "../types/PageData";
import TextBanner from '../components/TextBanner';
import {Helmet} from 'react-helmet';
import Navigation from '../components/Navigation';

function Legal() {
    const PageData = useLoaderData() as PageData
    const items = PageData.content.no_short_codes;
    const yoast:any = PageData.yoast_head_json;

    const imgRegex = /.*src="([^"]*)".*/;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [items]);

    //console.log(items)
  return (
    <div className='legal'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : 'The Odd Number | '+PageData.title.rendered}</title>
        </Helmet>
        <Navigation />
        <TextBanner 
            title={PageData.title.rendered}
            subtitle={items[0][0]}
            text={items[0][1][1]['paragraphs'][0]}
            background={items[0][1][0].replace(imgRegex, '$1')}
        />

        <div className="container post-content">
            <div className="row">
                <div className="col-12">
                    {items[1][0][0] && <div dangerouslySetInnerHTML={{__html: items[1][0][0]}} />}
                    {items[1][1] && <div dangerouslySetInnerHTML={{__html: items[1][1]['paragraphs']}} />}
                </div>
            </div>
            <div className="row pt-0">
                <div className="col-12">
                    {items[2][0][0] && <div dangerouslySetInnerHTML={{__html: items[2][0][0]}} />}
                    {items[2][1] && <div dangerouslySetInnerHTML={{__html: items[2][1]['paragraphs']}} />}
                </div>
            </div>
            <div className="row pt-0">
                <div className="col-12">
                    {items[3][0][0] && <div dangerouslySetInnerHTML={{__html: items[3][0][0]}} />}
                    {items[3][1] && <div dangerouslySetInnerHTML={{__html: items[3][1]['paragraphs']}} />}
                </div>
            </div>
            <div className="row pt-0">
                <div className="col-12">
                    {items[4][0][0] && <div dangerouslySetInnerHTML={{__html: items[4][0][0]}} />}
                    {items[4][1] && <div dangerouslySetInnerHTML={{__html: items[4][1]['paragraphs']}} />}
                </div>
            </div>
            <div className="row pt-0">
                <div className="col-12">
                    {items[5][0][0] && <div dangerouslySetInnerHTML={{__html: items[5][0][0]}} />}
                    {items[5][1] && <div dangerouslySetInnerHTML={{__html: items[5][1]['paragraphs']}} />}
                </div>
            </div>
            <div className="row pt-0">
                <div className="col-12">
                    {items[6][0][0] && <div dangerouslySetInnerHTML={{__html: items[6][0][0]}} />}
                    {items[6][1] && <div dangerouslySetInnerHTML={{__html: items[6][1]['paragraphs']}} />}
                </div>
            </div>
            <div className="row pt-0">
                <div className="col-12">
                    {items[7][0][0] && <div dangerouslySetInnerHTML={{__html: items[7][0][0]}} />}
                    {items[7][1] && <div dangerouslySetInnerHTML={{__html: items[7][1]['paragraphs']}} />}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Legal