import React from 'react';
import { Link } from 'react-router-dom';
import Heading from "./Heading";
import Paragraph from "./Paragraph";

interface BannerProps {
    post: any
}

const LatestPostBanner = ({ post }: BannerProps) => {
    if (!post) {
        return <div>Loading...</div>
    }


    return (
        <div className="container">
            <Link to={post.slug} className="latest-post-banner">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-5 order-md-1 order-2">
                        <p className='latest-post-category'>{post.categories[0]}</p>
                        <Heading Heading={post.title} Tag={'h1'}/>
                        {post.excerpt && <Paragraph Paragraphs={post.excerpt}/>}
                        <p className="latest-post-date">{post.date}</p>
                        <div className='btn btn__dark'>READ ARTICLE</div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-7 order-1 order-md-2 image">
                        <img src={post.thumbnail} alt="" />
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default LatestPostBanner;