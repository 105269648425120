import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
    title: string,
    sectionAnchor: string,
    color: string,
}

function NextSection({...props}) {
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        const targetElement = document.querySelector(props.sectionAnchor);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      };
  return (
    <a className={`next-section ${props.color}`} href={props.sectionAnchor} onClick={handleClick}>
        {props.title}
    </a>
        
  )
}

export default NextSection