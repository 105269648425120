import { createRoutesFromElements, createBrowserRouter, Route } from 'react-router-dom';


// Pages
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Error404 from "./pages/Error404";
import fetchData from "./functions/fetchData";
import Services from "./pages/Services";
import OurWork from "./pages/OurWork";
import Culture from "./pages/Culture";
import Careers from "./pages/Careers";
import News from "./pages/News";
import NewsSingle from "./pages/NewsSingle";
import ContactUs from "./pages/ContactUs";
import WorkSingle from "./pages/WorkSingle";
import Legal from "./pages/Legal";
import Layout from './layout/Layout';


export const router = createBrowserRouter(
  createRoutesFromElements(
      <Route path="/" element={<Layout />}>
        {/*Home*/}
        <Route
            element={<Home/>}
            path={'/'}
            loader = {async ({})=> await fetchData('page',8)}/>

        {/*About Us*/}
        <Route
            element={<AboutUs/>}
            path={'/about-us'}
            loader = {async ({})=> fetchData('page',10)}/>

        {/*Services*/}
        <Route
            element={<Services/>}
            path={'/services'}
            loader = {async ({})=> fetchData('page',12)}/>

        {/*Our Work*/}
        <Route
            element={<OurWork/>}
            path={'/our-work'}
            loader = {async ({})=> fetchData('page',14)}/>

        {/*Culture*/}
        <Route
            element={<Culture/>}
            path={'/culture'}
            loader = {async ({})=> fetchData('page',16)}/>

        {/*Individual Work*/}
        <Route
            element={<WorkSingle/>}
            path={'/our-work/:slug'}
            loader = {async ({ params })=> fetchData('our-work', params.slug)}/>

        {/*Careers*/}
        <Route
            element={<Careers/>}
            path={'/careers'}
            loader = {async ({})=> fetchData('page',18)}/>

        {/*Blog*/}
        <Route
            element={<News/>}
            path={'/news'}
            loader = {async ({})=> fetchData('page',20)}/>

        {/*Individual Blog*/}
        <Route
            element={<NewsSingle/>}
            path={'/news/:slug'}
            loader = {async ({ params })=> fetchData('posts',params.slug)}/>

        {/*Contact*/}
        <Route
            element={<ContactUs/>}
            path={'/contact-us'}
            loader = {async ({})=> fetchData('page',22)}/>

        {/*404*/}
        <Route
            element={<Error404/>}
            path={'*'}
            loader = {async ({})=> fetchData('page',24)}/>

        {/*Legal*/}
        <Route
            element={<Legal/>}
            path={'/legal'}
            loader = {async ({})=> fetchData('page',26)}/>
      </Route>
  )
)