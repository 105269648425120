import React, { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { PageData } from "../types/PageData";
import Pagination from '../components/Pagination';
import LatestPostBanner from '../components/LatestPostBanner';
import { Link } from 'react-router-dom';
import TextBanner from '../components/TextBanner';
import {Helmet} from 'react-helmet';
import Navigation from '../components/Navigation';

interface BlogPost {
    slug: string,
    title: string;
    content: string;
    categories: string[];
    thumbnail: string,
    date: string,
    excerpt: string
}

function News() {
    const [posts, setPosts] = useState<BlogPost[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [categories, setCategories] = useState([]);
    const [activeCategory, setActiveCategory] = useState<null | string>(null);
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const PageData = useLoaderData() as PageData

    const items = PageData.content.no_short_codes;
    const yoast:any = PageData.yoast_head_json;

    const shortcodeRegex = /(\[\/?[^\]]+\])/g;
    const imgRegex = /.*src="([^"]*)".*/;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [items]);

    useEffect(() => {

        const fetchPosts = async () => {
        try {
            setIsLoading(true);
            const res = await fetch(
            `${process.env.REACT_APP_CONTENT_URL}/wp-json/wp/v2/posts?_embed`
            );
            if (!res.ok) {
            throw new Error(res.statusText);
            }
            const data = await res.json();
            setPosts(data.map((post: any) => {
                const date = new Date(post.date);
                const options:any = { day: 'numeric', month: 'long', year: 'numeric' };
                const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
                return {
                    slug: post.slug,
                    title: post.title.rendered,
                    content: post.content.rendered,
                    excerpt: post.excerpt.rendered.replace(shortcodeRegex, ''),
                    categories: post._embedded["wp:term"][0].map((term: any) => term.name),
                    thumbnail: post._embedded["wp:featuredmedia"] && post._embedded["wp:featuredmedia"][0].media_details.sizes.full.source_url,
                    date: formattedDate
                }
            })); 
            setIsLoading(false);
        } catch (err: any) {
            setError(err);
            setIsLoading(false);
        }
        };
    
        const fetchCategories = async () => {
            try {
              const res = await fetch(
                `${process.env.REACT_APP_CONTENT_URL}/wp-json/wp/v2/categories?_embed`
              );
              const data = await res.json();
              const categories = data.map((category: any) => {
                return {
                  name: category.name,
                  postCount: category.count,
                };
              });
              setCategories(categories);
            } catch (err: any) {
              setError(err as Error);
            }
        };
    
        fetchPosts();
        fetchCategories();
    }, []);
      
    if (error) {
        return <div>An error occured: {error.message}</div>;
    }
        
    if (isLoading) {
        return <div className='news'>
            <Helmet>
                <title>{yoast !== undefined ? yoast.og_title : 'The Odd Number | '+PageData.title.rendered}</title>
            </Helmet>
            <Navigation />
            <TextBanner 
                title={PageData.title.rendered}
                subtitle={items[0][0]}
                text={items[0][1]['paragraphs']} 
            />
            <div className="news-loader">
                <span className="loader"></span>
            </div>
        </div>;
    }
    
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
        
    // Category filtering
    const handleCategoryChange = (category: string | null) => {
        setActiveCategory(category);
    };

    const filteredPosts = !posts ? [] : (
        activeCategory === null
            ? posts
            : posts.filter((post: BlogPost) => post.categories && post.categories.includes(activeCategory))
    );

    // Get the latest post
    const sortedPosts = filteredPosts.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    const latestPost = sortedPosts[0];
    
    // Pagination
    const totalPages = Math.ceil(filteredPosts.length / 9);
    const paginatedPosts = !filteredPosts ? [] : filteredPosts.slice(
    (currentPage - 1) * 9,
    currentPage * 9
    );
        // console.log(categories);
  return (
    <div className='news'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : 'The Odd Number | '+PageData.title.rendered}</title>
        </Helmet>
        <Navigation />
        <TextBanner 
            title={PageData.title.rendered}
            subtitle={items[0][0]}
            text={items[0][1][1]['paragraphs'][0]} 
            background={items[0][1][0].replace(imgRegex, '$1')}
        />
        <div className="container">
          <ul className='post-categories'>
            <li key="all" className={activeCategory === null ? 'active' : ''} onClick={() => handleCategoryChange(null)}>All</li>
            {categories.map((category: { name: string, postCount: any }) => (
              category.postCount > 0 && <li key={category.name} className={activeCategory === category.name ? 'active' : ''} onClick={() => handleCategoryChange(category.name)}>{category.name}</li>
            ))}
          </ul>
        </div>
        <div className="container">
            <LatestPostBanner post={latestPost} />
        </div>
        <div className="container blog-posts">
            <div className='row'>
                {paginatedPosts.map((post: BlogPost) => (
                    <div key={post.title} className='col-12 col-md-6 col-lg-4 blog-post'>
                        <Link key={post.title} to={`/news/${post.slug}`}>
                            <div className="image">
                                <img src={post.thumbnail} alt={post.title}/>
                                <div className="post-cat">{post.categories[0]}</div>
                            </div>
                            <div className="content">
                                <div className="post-date">{post.date}</div>
                                <h4 dangerouslySetInnerHTML={{ __html: post.title }} />
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
        <div className='container'>
            { posts.length > 1 && totalPages === 1 ? null : (
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    prevButtonVisible={currentPage > 1}
                    nextButtonVisible={currentPage < totalPages}
                />
            )}
        </div>
    </div>
  )
}

export default News