const fetchData = (type:string, id:number | string | undefined) => {
    if(type === 'page'){
      console.log('Testing ', process.env.REACT_APP_CONTENT_UR);
      return fetch(`${process.env.REACT_APP_CONTENT_URL}/wp-json/wp/v2/pages/${id}`)
          .then((res) => res.json())
    } else {
      return fetch(`${process.env.REACT_APP_CONTENT_URL}/wp-json/wp/v2/${type}?slug=${id}&_embed`)
          .then((res) => res.json())
    }
  };
  
  export default fetchData;