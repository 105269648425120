import React, { useState } from 'react';

interface accordionProps {
    id: string;
    onClick: (childId: string) => void;
    isSelected: boolean;
    position: string;
    description: string;
    requirements: string;
    responsibilities: string;
    buttonText: string;
    buttonLink: string;
}

function CareerAccordion({position, description, requirements, id, onClick, isSelected, responsibilities, buttonLink, buttonText}: accordionProps) {
    const [isActive, setIsActive] = useState(false);
    const aiButtonHref = /.*href="http:\/\/([^"]*)".*title="([^"]*)".*/;
  return (
    <div className='accordion career-accordion'>
        <div>
            <div onClick={() => onClick(id)} className={isSelected ? 'question active' : 'question'}>
                <h4>{position}</h4>
                <i className='icon'></i>
            </div>
            <div className={isSelected ? 'answer show' : 'answer hide'}>
                <div className="job">
                    <div className="description">
                        {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
                        {buttonLink && <a className='btn btn__light' href={`mailto:${buttonLink.replace(aiButtonHref, '$1')}`}>{buttonText.replace(aiButtonHref, '$2')}</a> }
                    </div>
                    <div className="details">
                        <div className="responsibilities">
                            {responsibilities && <div dangerouslySetInnerHTML={{ __html: responsibilities }} />}
                        </div>
                        <div className="requirements">
                            {requirements && <div dangerouslySetInnerHTML={{ __html: requirements }} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CareerAccordion