import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo }from '../assets/images/The-Odd-Number-Logo.svg';
import { ReactComponent as Facebook } from '../assets/images/icons/The-Odd-Number-Facebook-Dark.svg';
import { ReactComponent as Instagram } from '../assets/images/icons/The-Odd-Number-Instagram-Dark.svg';
import { ReactComponent as Twitter } from '../assets/images/icons/The-Odd-Number-Twitter-Dark.svg';
import Heading from './Heading';
import Paragraph from "./Paragraph";

function Footer() {
  return (
    <footer>
        <div className="container">
            <div className="row pb-0">
                <div className="col-12 col-md-3 col-lg-4">
                    <Heading className={'heading'} Tag={'h2'} Heading='Do you have the Odd project?' />
                </div>
                <div className="col-12 col-md-3 col-lg-2 sitemap">
                    <Heading className={'heading'} Tag={'h3'} Heading='Who we are' />
                    <ul>
                        <li className='nav-item'>
                            <Link to='/about-us'><Heading Heading={'About us'} Tag={'span'}/></Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/our-work'><Heading Heading={'Our Work'} Tag={'span'}/></Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/services#our-process'><Heading Heading={'Our Process'} Tag={'span'}/></Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/culture'><Heading Heading={'Culture'} Tag={'span'}/></Link>
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-md-3 col-lg-2">
                    <Heading className={'heading'} Tag={'h3'} Heading='Get in touch' />
                    <ul>
                        <li className='nav-item'>
                            <a href="tel:0103126190"><Heading Heading={'010 312 6190'} Tag={'span'}/></a>
                        </li>
                        <li className='nav-item'>
                            <a href="mailto:info@theoddnumber.co.za"><Heading Heading={'info@theoddnumber.co.za'} Tag={'span'}/></a>
                        </li>
                        <li className='nav-item address'>
                            <a href="https://goo.gl/maps/UvMr4XVcMTU2m6BL9" target="_blank" rel="noreferrer">
                                <Heading Heading={'<span>43 Peter Place,</span>'} Tag={'span'}/>
                                <Heading Heading={'<span>Lyme Park, Sandton </span>'} Tag={'span'}/>
                                <Heading Heading={'<span>2060</span>'} Tag={'span'}/>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-md-3 col-lg-2">
                    <Heading className={'heading'} Tag={'h3'} Heading='Legal fun stuff' />
                    <ul>
                        <li className='nav-item' style={{'--animation-delay': '1'} as React.CSSProperties}>
                            <a href={`${process.env.REACT_APP_CONTENT_URL}/wp-content/uploads/2023/05/The-Odd-Number-POPI-Framework-and-Privacy-Policy-15.04.2023.docx.pdf`}  target="_blank" rel="noreferrer"><Heading Heading={'Privacy Policy'} Tag={'span'}/></a>
                        </li>
                        <li className='nav-item'>
                            <a href={`${process.env.REACT_APP_CONTENT_URL}/wp-content/uploads/2023/05/The-Odd-Number-Website-Terms-and-Conditions.docx.pdf`}   target="_blank" rel="noreferrer"><Heading Heading={'Terms and Conditions'} Tag={'span'}/></a>
                        </li>
                        <li className='nav-item'>
                            <a href={`${process.env.REACT_APP_CONTENT_URL}/wp-content/uploads/2023/05/The-Odd-Number-PAIA-Manual.docx.pdf`} target="_blank" rel="noreferrer"><Heading Heading={'PAIA Manual'} Tag={'span'}/></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row pt-0">
                <div className="col-12 col-md-8 col-lg-4">
                    <Link to='contact-us' className='btn btn__light'>GET IN TOUCH</Link>
                    {/* <Link to='careers' className='btn btn__light'>CAREER OPPORTUNITIES</Link> */}
                </div>
                <div className="col-12 col-md-2">
                    <div className="links">
                        <a className='social-icon' href="https://www.facebook.com/pages/category/Local-business/The-Odd-Number-1684367518495651/" target="_blank" rel="noreferrer"><Facebook /></a>
                        <a className='social-icon' href="https://www.instagram.com/____theoddnumber____/" target="_blank" rel="noreferrer"><Instagram /></a>
                        <a className='social-icon' href="https://twitter.com/oddnumber_?lang=en" target="_blank" rel="noreferrer"><Twitter /></a>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright">
            <div className="container">
                <p>©Copyright The Odd Number. All rights reserved. Website by <a href="https://aglet.co.za" target='_blank' rel='noreferrer'>Aglet.</a></p>
            </div>
        </div>
    </footer>
  )
}

export default Footer