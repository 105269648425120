import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
    image: string;
    title: string;
    subtitle: string;
    buttonHref: string;
}

function SliderSingle({...props}) {
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;
  return (
    <div className='single-slider'>
        <div className="single-slider__image">
            <img src={props.image && props.image} alt="Slider item" />
        </div>
        <div className="single-slider__content">
            {props.title && <div dangerouslySetInnerHTML={{ __html: props.title }} />}
            {props.subtitle && <div dangerouslySetInnerHTML={{ __html: props.subtitle }} />}
            {props.buttonHref && <Link to={props.buttonHref.replace(aiButtonHref, '$1')} className="btn btn__dark">{props.buttonHref.replace(aiButtonHref, '$2')}</Link>}
        </div>
    </div>
  )
}

export default SliderSingle