import React, {useEffect, useState, useRef} from 'react';
import paragraph from "../components/Paragraph";

const ParagraphAnimation = (Paragraph:string) => {

  const [inView, setInView] = useState(false);
  const [lines, setLines] = useState<any>([]);
  const textContainerRef = useRef<any>(null);
  const testRef = useRef<any>(null);
  const textContainer = useRef<any>(null);


  useEffect(() => {
    const testEl = testRef.current;
    const words = Paragraph.split(' ');
    let line:any = [];
    let output = [];

    for (let i = 0; i < words.length; i++) {
      line.push(words[i]);
      testEl.innerHTML = line.join(' ');
      if (testEl.scrollWidth > testEl.clientWidth) {
        line.pop();
        output.push(line.join(' '));
        line = [words[i]];
        testEl.innerHTML = line.join(' ');
      }
    }

    output.push(line.join(' '));
    setLines(output);
  }, [Paragraph]);


  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setInView(true);
        }
      });
    });

    if (textContainerRef.current) {
      observer.observe(textContainerRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [textContainerRef]);



  return (
      <React.Fragment>
        <span className={'test-lines ai-split-line'} ref={testRef}></span>
        <span ref={textContainerRef} className={'ai-split-text-container'}>
                <span className={`ai-split-line ${inView && 'animate'}`} ref={textContainer}>
                    {
                      lines.map((line:string, index: number)=>(
                          <span className={'line'} key={index} style={{"--animation-delay": index + 1} as React.CSSProperties} dangerouslySetInnerHTML={{__html: line}}>
                          </span>
                      ))
                    }
                </span>
            </span>
      </React.Fragment>
  );
};

export default ParagraphAnimation;