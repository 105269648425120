import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function CookieNotice() {
    const [hidden, setHidden] = useState(false);
    const [hasClass, setHasClass] = useState(false);
    const [showCookieNotice, setShowCookieNotice] = useState(true);
    
    useEffect(() => {
        const acceptedCookieNotice = localStorage.getItem('acceptedCookieNotice');
        if (acceptedCookieNotice === 'yes') {
          setShowCookieNotice(false);
          setHidden(true)
        }
    }, []);
        
    const handleAcceptCookieNotice = () => {
        localStorage.setItem('acceptedCookieNotice', 'yes');
        setShowCookieNotice(false);
        setHidden(true)
    };
  return (
    <div className={hidden ? "cookie-notice hide" : "cookie-notice"}>
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-8 col-lg-9">
                    <p>
                    This website uses cookies to ensure the best experience on our website. By proceeding you accept the terms of the Privacy Policy. <Link to='/legal'>Read more.</Link>
                    </p>
                </div>
                <div className="col-12 col-md-4 col-lg-3 actions">
                    <button onClick={handleAcceptCookieNotice} className='btn btn__light'>I UNDERSTAND</button>
                    <button onClick={handleAcceptCookieNotice} className="close-btn close"></button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CookieNotice