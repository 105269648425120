import React, { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { PageData } from "../types/PageData";
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Navigation from '../components/Navigation';
import Heading from "../components/Heading";

interface Post {
    id: number;
    title: string;
    content: {
      rendered: string;
    };
    thumbnailUrl: string;
    slug: string;
    video: string;
}
  
  function OurWork() {
    const [isLoading, setIsLoading] = useState(true);
    const [posts, setPosts] = useState<Post[]>([]);
    const [isHovering, setIsHovering] = useState(false);

    const PageData = useLoaderData() as PageData;
    const items = PageData.content.no_short_codes;

    const yoast:any = PageData.yoast_head_json;
  
    useEffect(() => {
        // fetch("https://toncontent.agletinteractive.com/wp-json/wp/v2/our-work")
        //   .then((response) => response.json())
        //   .then(async (data) => {
        //     const postsWithThumbnails = await Promise.all(
        //       data.map(async (post: any) => {
        //         const thumbnailResponse = await fetch(
        //           `https://toncontent.agletinteractive.com/wp-json/wp/v2/media/${post.featured_media}`
        //         );
        //         const thumbnailData = await thumbnailResponse.json();
        //         const thumbnailUrl = thumbnailData.source_url;
        //         return {
        //           id: post.id,
        //           title: post.title,
        //           content: post.content,
        //           slug: post.slug,
        //           thumbnailUrl,
        //         };
        //       })
        //     );
        //     setPosts(postsWithThumbnails);
        //     setIsLoading(false);
        //   })
        //   .catch((error) => console.error(error));

        fetch(`${process.env.REACT_APP_CONTENT_URL}/wp-json/ai-custom/v1/custom-post-type-info/?post_type=our-work`)
        .then( (response) => response.json() )
        .then( async (data) => {
          const postsWithThumbnails = await Promise.all(
            data.map(async (post: any) => {
              
              //console.log(post);
              return {
                id: post.id,
                title: post.title,
                //content: post.content,
                slug: post.slug,
                thumbnailUrl: post.featured_img,
                video: post.video,
              };
            })
          );
          setPosts(postsWithThumbnails);
          setIsLoading(false);
        })
        .catch((error) => console.error(error));

      }, []);

      useEffect(() => {
        window.scrollTo(0, 0);
      }, [items]);
      // console.log(posts);
    return (
        <div className={isHovering ? "hover our-work" : "our-work"}>
            <Helmet>
                <title>{yoast !== undefined ? yoast.og_title : 'The Odd Number | '+PageData.title.rendered}</title>
            </Helmet>
            <Navigation />
            <div className="container content">
            <div className="row">
                <div className="col-12 col-md-10 col-lg-7">
                  {items[0][0][0] && <Heading Heading={items[0][0][0]} Tag={'h1'}/>}
                </div>
            </div>
            {isLoading ? (
                <span className="loader"></span>
            ) : (
                posts.map((post) => (
                <div
                    className="work-item"
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                    key={post.id}
                >
                    <Link to={`/our-work/${post.slug}`} data-target={post.slug}>
                    <h2>{post.title}</h2>
                    </Link>
                    { post.video === '' ? 
                    <img className='work-image' src={post.thumbnailUrl} alt={post.title} /> :
                    <> <iframe className='work-image' width="940" height="529" src={`https://www.youtube.com/embed/${post.video}?&autoplay=1&mute=1&loop=1&controls=0&rel=0&playlist=${post.video}`} title="Sipho &quot;Hotstix&quot; Mabuse  -Assupol Rest Assured Campaign" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe> <img className='work-image mobile-placeholder' src={post.thumbnailUrl} alt={post.title} /> </> }
                </div>
                ))
            )}
            </div>
      </div>
    );
  }
export default OurWork