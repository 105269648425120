import React, { useState } from 'react';

import { ReactComponent as Facebook } from '../assets/images/icons/The-Odd-Number-Facebook-Dark.svg';
import { ReactComponent as Twitter } from '../assets/images/icons/The-Odd-Number-Twitter-Dark.svg';
import { ReactComponent as LinkIcon } from '../assets/images/icons/links-line.svg';

interface Props {
    url: string;
    title: string;
    twitterHandle?: string;
  }

function ShareLinks({ url, title, twitterHandle = '' }: Props) {

    const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    const twitterShareLink = twitterHandle
      ? `https://twitter.com/share?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}&via=${encodeURIComponent(twitterHandle)}`
      : `https://twitter.com/share?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`;

    const [isCopied, setIsCopied] = useState(false);

    const handleCopyClick = () => {
    navigator.clipboard.writeText(url);
    setIsCopied(true);
    };

  return (
    <div className="sharelinks">
        <a className='social-icon' href={facebookShareLink} target="_blank" rel="noreferrer"><Facebook /></a>
        <a className='social-icon' href={twitterShareLink} target="_blank" rel="noreferrer"><Twitter /></a>
        <button className='social-icon' title='Copy link' onClick={handleCopyClick}>
            <LinkIcon />
        </button>
    </div>
  )
}

export default ShareLinks