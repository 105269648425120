import React from 'react';
import {motion} from "framer-motion";


const regex = /(<([^>]+)>)/ig;
const Heading = (Heading:string, inView:boolean) => {
  //SAVE ORIGINAL
  const OriginalTitle = Heading;
  let animatedTitle;
  let animatedTitleArray:any[] = []

  //Remove H2
  animatedTitle = Heading.replace(/<(?!\/?b>|\/?strong>)[^>]+>/g, '');

  //split title into spans keeping strong tags in place
  animatedTitleArray = animatedTitle.split(/(<strong>.*?<\/strong>)|\s/g)
  //Remove undefined and empty strings from array
  animatedTitleArray = animatedTitleArray.filter((word: string) => word !== undefined && word !== '')
  //Add Empty spaces after words
  animatedTitleArray = animatedTitleArray.map((word: string) => word + '&nbsp;');

  return (
      animatedTitleArray.map((word, index)=>{
        return (
            <span className={`ai-reveal-box`} key={index}>
              <motion.span
                  initial={{opacity: 1, y: '100%'}}
                  animate={inView && {opacity: 1, y: '0'}}
                  transition={{delay: index * 0.2, duration: 0.8}}
                  className={'ai-animated-word'} dangerouslySetInnerHTML={{__html: word}}>
              </motion.span>
            </span>
        )
      })
  );
};

export default Heading;