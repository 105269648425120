import React from 'react';
import { Link } from "react-router-dom";
import Heading from "./Heading";
import Paragraph from "./Paragraph";

interface Props {
    background: string,
    buttonHref: string,
    buttonText: string,
    copy: string,
    title: string,
    subtitle: string,
}

function Cta({...props}) {
  return (
    <div className='cta' style={{backgroundImage: `url(${props.background})`}}>
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 heading">
                    {props.subtitle && <div className='subtitle' dangerouslySetInnerHTML={{__html: props.subtitle}} />}
                    {props.title && <Heading Tag={'h2'} Heading={props.title} />}
                </div>
                <div className="col-12 col-md-6 content">
                    {/*{props.copy && <div className='copy' dangerouslySetInnerHTML={{__html: props.copy}} />}*/}
                    {props.copy && <Paragraph Paragraphs={props.copy} className={'copy'} />}
                    {props.buttonHref && <Link className='btn btn__light' to={props.buttonHref}>{props.buttonText}</Link>}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Cta