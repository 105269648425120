import React, {useEffect} from 'react';
import ParagraphAnimation from "../helpers/ParagraphAnimation";
import {type} from "os";

interface Paragraph{
  Paragraphs: string | { paragraphs:string[] | any },
  className?: string,
  paragraphClassName?: string
}

const Paragraph = ({Paragraphs, className, paragraphClassName}:Paragraph) => {
  const objectRegex = /(<([^>]+)>)/ig;
  const stringRegex = /<(?!\/?b>|\/?strong>)[^>]+>/g;

  return (
      <div className={className}>
        {typeof Paragraphs === "object" && Paragraphs['paragraphs'] &&
          Paragraphs['paragraphs'].map((paragraph:string)=>(
            <p className={paragraphClassName}>{ParagraphAnimation(paragraph.replace(objectRegex, ''))}</p>
          ))
        }
        {typeof Paragraphs === 'string' &&
            <p className={paragraphClassName}>{Paragraphs !== undefined && ParagraphAnimation(Paragraphs.replace(stringRegex, ''))}</p>
        }
      </div>
  );
};

export default Paragraph;