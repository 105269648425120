import React, {useEffect, useState} from 'react';
import HeadingAnimation from "../helpers/HeadingAnimation";

interface Heading{
  Heading:string,
  Tag:string,
  className?: string
}

const Heading = ({Heading, Tag, className}:Heading) => {
  const [inView, setInView] = useState(false);
  const headingRef = React.useRef<HTMLImageElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setInView(true);
        }
      });
    });

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [headingRef]);

  return (
        <div ref={headingRef} className={className}>
          {Tag === 'h1' && <h1 ref={headingRef}>{HeadingAnimation(Heading, inView)}</h1>}
          {Tag === 'h2' && <h2 ref={headingRef}>{HeadingAnimation(Heading, inView)}</h2>}
          {Tag === 'h3' && <h3 ref={headingRef}>{HeadingAnimation(Heading, inView)}</h3>}
          {Tag === 'h4' && <h4 ref={headingRef}>{HeadingAnimation(Heading, inView)}</h4>}
          {Tag === 'span' && <span ref={headingRef}>{HeadingAnimation(Heading, inView)}</span>}
        </div>
  )
};

export default Heading;