import React, { useState } from 'react';

interface accordionProps {
    id: string;
    onClick: (childId: string) => void;
    isSelected: boolean;
    question: string;
    answer: string;
}

function Accordion({question, answer, id, onClick, isSelected}: accordionProps) {
    const [isActive, setIsActive] = useState(false);
  return (
    <div className='accordion'>
        <div>
            <div onClick={() => onClick(id)} className={isSelected ? 'question active' : 'question'}>
                <h4 dangerouslySetInnerHTML={{ __html: question }} />
                <i className='icon'></i>
            </div>
            <div className={isSelected ? 'answer show' : 'answer hide'}>
                <p>{answer}</p>
            </div>
        </div>
    </div>
  )
}

export default Accordion