import React, { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import TextBanner from '../components/TextBanner';
import { PageData } from "../types/PageData";
import { Link } from 'react-router-dom';
import Accordion from '../components/Accordion';
import NextSection from '../components/NextSection';
import VideoModal from '../components/VideoModal';
import SliderSingle from '../components/SliderSingle';
import Slider from 'react-slick';
import {Helmet} from 'react-helmet';

import nextArrow from '../assets/images/icons/The-Odd-Number-Arrow-Right-Light.svg';
import prevArrow from '../assets/images/icons/The-Odd-Number-Arrow-Left-Light.svg';
import ContentImage from '../components/ContentImage';
import ContentImageFullWidth from '../components/ContentImageFullWidth';
import Cta from '../components/Cta';
import Navigation from '../components/Navigation';
import Paragraph from "../components/Paragraph";
import Heading from "../components/Heading";

function Services() {
    const [selectedChild, setSelectedChild] = useState('1');
    const [presentPast, setPresentPast] = useState<string>('present');
    const PageData = useLoaderData() as PageData;
    const items = PageData.content.no_short_codes;
    const yoast:any = PageData.yoast_head_json;
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;
    const imgRegex = /.*src="([^"]*)".*/;

    const handleClick = (childId: string) => {
        setSelectedChild(childId);
        if(selectedChild === childId) {
            setSelectedChild('');
        }

        const href = items.find((item) => item.id === childId)?.href;
          if (href && href.includes('#our-process')) {
            const element = document.getElementById('our-process');
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    const singleSliderSettings:any = {
        dots: false,
        infinite: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        autoplay: false,
        arrows: false,
        nextArrow: <img src={nextArrow} alt='' />,
        prevArrow: <img src={prevArrow} alt='' />,
    }

    useEffect(() => {
        //document.body.style.scrollBehavior = "auto";
        document.body.scrollTop = 0;
        //document.documentElement.style.scrollBehavior = "auto";
        document.documentElement.scrollTop = 0;
    }, []);

    const id = window.location.hash.substring(1);

    useEffect(() => {
        if (id) {
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }
    }, []);

  return (
    <div className='services'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : 'The Odd Number | '+PageData.title.rendered}</title>
        </Helmet>
        <Navigation />
        <TextBanner 
            title={PageData.title.rendered}
            subtitle={items[0][0]}
            text={items[0][1][1]['paragraphs'][0]}
            background={items[0][1][0].replace(imgRegex, '$1')}
        />

        <div className="component accordion-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-5">
                        {items[1][0][0] && <Heading Heading={items[1][0][0]} Tag={'h2'} />}
                        {items[1][1][1] && <Paragraph Paragraphs={items[1][1][1]['paragraphs']} />}
                        {items[1][1][0] && <Link className='btn btn__light' to={items[1][1][0].replace(aiButtonHref, '$1')}>{items[1][1][0].replace(aiButtonHref, '$2')}</Link>}
                    </div>
                    <div className="col-12 col-md-6">
                        <Accordion 
                            id="1" onClick={handleClick} isSelected={selectedChild === '1'}
                            question={items[2][0]}
                            answer={items[2][1]}
                        />

                        <Accordion 
                            id="2" onClick={handleClick} isSelected={selectedChild === '2'}
                            question={items[2][2]}
                            answer={items[2][3]}
                        />

                        <Accordion 
                            id="3" onClick={handleClick} isSelected={selectedChild === '3'}
                            question={items[2][4]}
                            answer={items[2][5]}
                        />

                        <Accordion 
                            id="4" onClick={handleClick} isSelected={selectedChild === '4'}
                            question={items[2][6]}
                            answer={items[2][7]}
                        />

                        <Accordion 
                            id="5" onClick={handleClick} isSelected={selectedChild === '5'}
                            question={items[2][8]}
                            answer={items[2][9]}
                        />

                        <Accordion 
                            id="6" onClick={handleClick} isSelected={selectedChild === '6'}
                            question={items[2][10]}
                            answer={items[2][11]}
                        />

                        <Accordion 
                            id="7" onClick={handleClick} isSelected={selectedChild === '7'}
                            question={items[2][12]}
                            answer={items[2][13]}
                        />
                    </div>
                </div>
            </div>
            <div className="container">
                <NextSection 
                    title='OUR CLIENTS'
                    sectionAnchor='#our-clients'
                    color='light'
                />
            </div>
        </div>

        <div id="our-clients" style={{backgroundImage: `url(${items[3][1][0].replace(imgRegex, '$1')})`}} >
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-6">
                        {items[3][0][0] && <Heading Heading={items[3][0][0]} Tag={'h2'}/>}
                    </div>
                    <div className="col-12">
                        <div className="tab-links">
                            {/* <button className={presentPast === 'present' ? 'active' : ''} onClick={() => setPresentPast('present')}>CURRENT CLIENTS</button> */}
                            <button className={presentPast === 'past' ? 'active' : ''} onClick={() => setPresentPast('past')}>CLIENTS</button>
                        </div>
                        <div className="tabs">
                            <div className={presentPast === 'present' ? 'active' : 'active'}>
                                <div className="logos">
                                    {items[4].map((item:any, index:any) => {
                                        return (
                                            <div dangerouslySetInnerHTML={{__html: items[4][index]}} key={index} />
                                        )
                                    })}
                                </div>
                            </div>
                            <div className={presentPast === 'past' ? 'hide' : 'hide'}>
                                <div className="logos">
                                    {items[5].map((item:any, index:any) => {
                                        return (
                                            <div dangerouslySetInnerHTML={{__html: items[5][index]}} key={index} />
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="link">
                            <Link to='/our-work' className='btn btn__light'>BROWSE OUR WORK</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <VideoModal 
            videoThumbnail={items[6][0]}
            video={items[6][1]+items[6][2]}
            placeholder={items[6][3][0].replace(imgRegex, '$1')}
        />

        <div className="component single-slider-wrapper" style={{backgroundImage: `url(${items[7][0].replace(imgRegex, '$1')})`}}>
            <div className="container">
                <Slider {...singleSliderSettings}>
                    <SliderSingle 
                        image={items[7][1].replace(imgRegex, '$1')}
                        title={items[7][2][0]}
                        subtitle={items[7][3][0]}
                        buttonHref={items[7][4][0]}
                    />
                
                    <SliderSingle 
                        image={items[7][5][0].replace(imgRegex, '$1')}
                        title={items[7][6][0]}
                        subtitle={items[7][7][0]}
                        buttonHref={items[7][8][0]}
                    />

                    <SliderSingle 
                        image={items[7][9][0].replace(imgRegex, '$1')}
                        title={items[7][10][0]}
                        subtitle={items[7][11][0]}
                        buttonHref={items[7][12][0]}
                    />
                </Slider>
            </div>
            <div className="container">
                <NextSection 
                    title='OUR SERVICES'
                    sectionAnchor='#our-process'
                    color='light'
                />
            </div>
        </div>
        <div id="our-process">
            <div className="container">
                <div className="row pb-0">
                    <div className="col-12 col-md-6 ml-auto">
                        {items[8][0][0] && <Heading Heading={items[8][0][0]} Tag={'h2'} />}
                        {items[8][1] && <Paragraph Paragraphs={items[8][1]}/>}
                    </div>
                </div>
            </div>
            <ContentImage 
                layout='right text'
                image={items[9][0].replace(imgRegex, '$1')}
                number={items[9][1]}
                title={items[9][2][0]}
                copy={items[9][3]}
            />

            <ContentImageFullWidth 
                layout='left text'
                image={items[10][0].replace(imgRegex, '$1')}
                number={items[10][1]}
                title={items[10][2][0]}
                copy={items[10][3]}
            />

            <ContentImageFullWidth 
                layout='right text'
                image={items[11][0].replace(imgRegex, '$1')}
                number={items[11][1]}
                title={items[11][2][0]}
                copy={items[11][3]}
            />

            <ContentImageFullWidth 
                layout='left text'
                image={items[12][0].replace(imgRegex, '$1')}
                number={items[12][1]}
                title={items[12][2][0]}
                copy={items[12][3][0]}
            />

            <ContentImageFullWidth 
                layout='right text'
                image={items[13][0].replace(imgRegex, '$1')}
                number={items[13][1]}
                title={items[13][2][0]}
                copy={items[13][3][0]}
            />
        </div>

        <Cta 
            background={items[14][0].replace(imgRegex, '$1')}
            title={items[14][1][0]}
            copy={items[14][2][2]}
            buttonHref={items[14][2][0].replace(aiButtonHref, '$1')}
            buttonText={items[14][2][0].replace(aiButtonHref, '$2')}
        />
    </div>
  )
}

export default Services