import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';

import image from '../assets/images/team/The-Odd-NumberAyanda_.jpeg';
import nextArrow from '../assets/images/icons/The-Odd-Number-Arrow-Right-Dark.svg';
import prevArrow from '../assets/images/icons/The-Odd-Number-Arrow-Left-Dark.svg';

interface Post {
    id: number;
    title: {
      rendered: string;
    };
    content: {
      rendered: string;
    };
    thumbnailUrl: string;
}

function AllTeamSlider() {
    const [posts, setPosts] = useState<Post[]>([]);
    const settings:any = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        autoplay: true,
        nextArrow: <span className={'ai-slider-arrow ai-slider-arrow-next'}><img src={nextArrow} alt='' /> <img className={'slick-arrow-transition'} src={nextArrow} alt='' /></span>,
        prevArrow: <span className={'ai-slider-arrow ai-slider-arrow-next'}><img src={prevArrow} alt='' /> <img src={prevArrow} alt='' className={'slick-arrow-transition'}/></span>,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_CONTENT_URL}/wp-json/wp/v2/team?status=publish`)
          .then((response) => response.json())
          .then(async (data) => {
            const postsWithThumbnails = await Promise.all(
              data.map(async (post: any) => {
                const thumbnailResponse = await fetch(
                  `${process.env.REACT_APP_CONTENT_URL}/wp-json/wp/v2/media/${post.featured_media}`
                );
                const thumbnailData = await thumbnailResponse.json();
                const thumbnailUrl = thumbnailData.source_url;
                return {
                  id: post.id,
                  title: post.title,
                  content: post.content,
                  thumbnailUrl,
                };
              })
            );
            setPosts(postsWithThumbnails);
          })
          .catch((error) => console.error(error));
    }, []);

  return (
    <div className='team-slider'>
        <Slider {...settings} >
            {posts.map((item:any) => (
                <div key={item.id} className='team-slider__item'>
                    <img src={item.thumbnailUrl} alt="" />
                    <div className="team-slider__content">
                        <div>
                            <h4>{item.title.rendered}</h4>
                            <div dangerouslySetInnerHTML={{ __html: item.content.rendered }}></div>
                        </div>
                    </div>
                </div>
            ))}
        </Slider>
    </div>
  )
}

export default AllTeamSlider