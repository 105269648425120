import React from 'react';
import { Link } from 'react-router-dom';
import Heading from "./Heading";
import Paragraph from "./Paragraph";

interface Props {
    background: string,
    title: string,
    category: string,
    description: string,
    buttonHref: string,
    videoId: string,
}
function SelectedProducts({...props}) {
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;
  return (
    <Link to={props.buttonHref.replace(aiButtonHref, '$1')} className='selected-product'>
        {props.videoId === '' ?  <div className='selected-product__bg' style={{backgroundImage: `url(${props.background})`}} /> : <iframe className='selected-product__bg iframe' width="940" height="529" src={`https://www.youtube.com/embed/${props.videoId}?&autoplay=1&mute=1&loop=1&controls=0&rel=0&playlist=${props.videoId}`} title="Sipho &quot;Hotstix&quot; Mabuse  -Assupol Rest Assured Campaign" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe> }

        <div className="container">
            <div className="row">
                <div className="col-12 col-md-5">
                    {props.category && <div dangerouslySetInnerHTML={{__html: props.category}} />}
                    <Heading Heading={props.title} Tag={'h3'}/>
                </div>
                <div className="col-12 col-md-7">
                    <Paragraph Paragraphs={props.description}/>
                    {props.buttonHref && <span className='btn btn__dark' >{props.buttonHref.replace(aiButtonHref, '$2')}</span>}
                </div>
            </div>
        </div>
    </Link>
  )
}

export default SelectedProducts