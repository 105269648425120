import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import Heading from "./Heading";
import Paragraph from "./Paragraph";

interface Props {
    title: string,
    subtitle: string,
    text: string,
    background: string,
}

function TextBanner({...props}) {
  const [inView, setInView] = useState(false);
  const containerRef = React.useRef<HTMLImageElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setInView(true);
          observer.unobserve(entry.target);
        }
      });
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [containerRef]);

  return (
    <div className='text-banner' style={{backgroundImage: `url(${props.background})`}}>
        <div className={`container ${inView && 'animating'}`} ref={containerRef}>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-7 text-banner__heading">
                    {props.title && <motion.h1 initial={{opacity: 0, x: '-10%'}}
                                               style={{overflow: 'hidden'}}
                                               animate={inView && {opacity: 1, x: '0'}}
                                               transition={{duration: 1}}
                                               dangerouslySetInnerHTML={{__html: props.title}} />}
                </div>
                <div className="col-12 col-md-6 col-lg-5 text-banner__content">
                    {props.subtitle && <Heading className={'heading'} Tag={'h2'} Heading={props.subtitle[0]} />}
                    {props.text && <Paragraph Paragraphs={props.text} className='content'/>}
                </div>
            </div>
        </div>
    </div>
  )
}

export default TextBanner