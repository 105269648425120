import React from 'react';
import { Outlet } from 'react-router-dom';
import CookieNotice from '../components/CookieNotice';
import Footer from '../components/Footer';
function Layout() {
  return (
    <div>
        <main>
            <Outlet />
        </main>
        <CookieNotice />
        <Footer />
    </div>
  )
}

export default Layout