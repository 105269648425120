import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as Logo }from '../assets/images/The-Odd-Number-Logo.svg';
import { ReactComponent as Facebook } from '../assets/images/icons/The-Odd-Number-Facebook-Dark.svg';
import { ReactComponent as Instagram } from '../assets/images/icons/The-Odd-Number-Instagram-Dark.svg';
import { ReactComponent as Twitter } from '../assets/images/icons/The-Odd-Number-Twitter-Dark.svg';
import { ReactComponent as LogoLight } from '../assets/images/The-Odd-Number-Nav-Logo.svg';

function Navigation() {
    //nav open and close state
    const [isOpen, setIsOpen] = useState(false);
    const navigationRef = useRef<HTMLDivElement>(null);
    const [isScrolled, setIsScrolled] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const navigation = navigationRef.current;
    
        const handleScroll = () => {
          if (window.scrollY > 10) {
            setIsScrolled(true)
          } else {
            setIsScrolled(false)
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

  return (
    <div ref={navigationRef} className={`navigation ${isOpen ? 'nav-open ' : ''}${isScrolled ? ' scrolled' : ''}`}>
        <div className="container navigation__bar">
            <Link onClick={() => setIsOpen(false)} to='/' className="logo"><Logo /></Link>
            <div className="navigation__items">
                <div className="navigation__socials">
                    <a className='social-icon' href="https://www.facebook.com/pages/category/Local-business/The-Odd-Number-1684367518495651/" target="_blank" rel="noreferrer"><Facebook /></a>
                    <a className='social-icon' href="https://www.instagram.com/____theoddnumber____/" target="_blank" rel="noreferrer"><Instagram /></a>
                    <a className='social-icon' href="https://twitter.com/oddnumber_?lang=en" target="_blank" rel="noreferrer"><Twitter /></a>
                </div>
                <Link to="/contact-us" className='btn btn__dark' data-text={'WORK WITH US'}>WORK WITH US</Link>
                <button onClick={() => setIsOpen(!isOpen)} className={isOpen ? 'navigation__toggler open' : 'navigation__toggler'}>
                    <span style={{"--animation-delay": '0'} as React.CSSProperties} className="fill"></span>
                    <span style={{"--animation-delay": '1'} as React.CSSProperties} className="fill"></span>
                    <span style={{"--animation-delay": '2'} as React.CSSProperties} className="fill"></span>
                </button>
            </div>
        </div>
        <div className={isOpen ? 'navigation__menu-wrapper open' : 'navigation__menu-wrapper'}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-8">
                        <h2>MENU</h2>

                        <ul className='navigation__nav-items'>
                            <li className={location.pathname === '/about-us' ? 'active' : ''} style={{'--animation-delay': '0'} as React.CSSProperties}>
                                <Link to='/about-us'>About us</Link>
                            </li>
                            <li className={location.pathname === '/services' ? 'active' : ''} style={{'--animation-delay': '1'} as React.CSSProperties}>
                                <Link to='/services'>What we do</Link>
                            </li>
                            <li className={location.pathname === '/our-work' ? 'active' : ''} style={{'--animation-delay': '2'} as React.CSSProperties}>
                                <Link to='/our-work'>Our Work</Link>
                            </li>
                            <li className={location.pathname === '/services#our-services' ? 'active' : ''} style={{'--animation-delay': '3'} as React.CSSProperties}>
                                {location.pathname === '/services' ? <a onClick={() => setIsOpen(!isOpen)} href='#our-process'>Our Process</a> : <Link to='/services#our-process'>Our Process</Link>}
                            </li>
                            <li className={location.pathname === '/culture' ? 'active' : ''} style={{'--animation-delay': '4'} as React.CSSProperties}>
                                <Link to='/culture'>Culture</Link>
                            </li>
                            {/* <li className={location.pathname === '/careers' ? 'active' : ''} style={{'--animation-delay': '5'} as React.CSSProperties}>
                                <Link to='/careers'>Careers</Link>
                            </li> */}
                            <li className={location.pathname === '/news' ? 'active' : ''} style={{'--animation-delay': '6'} as React.CSSProperties}>
                                <Link to='/news'>News & Insights</Link>
                            </li>
                            <li className={location.pathname === '/contact-us' ? 'active' : ''} style={{'--animation-delay': '7'} as React.CSSProperties}>
                                <Link to='/contact-us'>Contact us</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-4 contacts">
                        <div className="contact-info">
                            <p><strong>Tel:</strong> <a href="tel:0103126190">010 312 6190</a></p>
                            <p><strong>Email:</strong> <a href="mailto:info@theoddnumber.co.za">info@theoddnumber.co.za</a></p>
                            <div className="links">
                                <a className='social-icon' href="https://www.facebook.com/pages/category/Local-business/The-Odd-Number-1684367518495651/" target="_blank" rel="noreferrer"><Facebook /></a>
                                <a className='social-icon' href="https://www.instagram.com/____theoddnumber____/" target="_blank" rel="noreferrer"><Instagram /></a>
                                <a className='social-icon' href="https://twitter.com/oddnumber_?lang=en" target="_blank" rel="noreferrer"><Twitter /></a>
                            </div>
                            <Link onClick={() => setIsOpen(!isOpen)} className='btn btn__light' to='contact-us'>WORK WITH US</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Navigation