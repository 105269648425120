import React, {useEffect, useState, useRef} from 'react';
import logo from '../assets/images/The-Odd-Number-Home-Banner-Logo.svg';

import {Player, Controls} from '@lottiefiles/react-lottie-player';
import lottieAnimation from '../assets/images/lottie-animations/the-odd-number-loader.json'

interface Props {
    video: any,
    placeholder: any,
}

function extractVideoId(video: string): string | null {
    const match = video.match(/youtube\.com\/embed\/(\w+)/i);
    if (match) {
      return match[1];
    } else {
      return null;
    }
}

function VideoBgBanner({...props}) {
    const videoId = extractVideoId(props.video);
    const playerRef = React.useRef<any>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [hover, setHover] = useState(false);
    const [hasClass, setHasClass] = useState(false);

    const iframeRef = useRef<HTMLDivElement>(null)

    function handlePlayerEvents(event: any) {
        if(event){
        document.body.style.overflow = "hidden"
        }
        if(event === 'complete'){
        props.setLoading(false)
        document.body.style.overflow = "auto"
        }
    }

    useEffect(()=>{
        if(playerRef.current !== null){
        playerRef.current.setPlayerSpeed(100);
        }
    }, [playerRef])

    useEffect(()=>{
        if(iframeRef.current){
        iframeRef.current.children[0].setAttribute('width', '1199')
        iframeRef.current.children[0].setAttribute('height', '600')
        }
    })
         
    const handleMouseEnter = () => {
        setHover(true);
    };
        
    const handleMouseLeave = () => {
        setHover(false);
    };
        
    const handleHover = () => {
        if (hasClass) {
            setHasClass(false);
        } else {
            setHasClass(true);
            if (500) {
            setTimeout(() => {
                setHasClass(false);
            }, 500);
            }
        }
    };

  return (
    <div className='video-bg-banner'  onClick={() => setIsOpen(!isOpen)}>
        <div className='video-bg-banner__video'>
            <iframe src={`https://www.youtube.com/embed/${videoId}?&autoplay=1&mute=1&loop=1&controls=0&rel=0&playlist=${videoId}&showinfo=0&modestbranding=1`} title="TON Web with mute final" allow='autoplay; encrypted-media; picture-in-picture'></iframe>
            <img src={props.placeholder} alt="" className="mobile-placeholder" />
        </div>
        {isOpen && (
        <>
            <div className="modal-overlay" onClick={() => setIsOpen(!isOpen)} >
            </div>
            <button className={`${isOpen ? 'open' : 'notOpen'} close close-btn ${hasClass ? '' : ""}`} data-animation='xMarks' data-remove={500} onMouseEnter={() => setHasClass(true)} onMouseLeave={() => setHasClass(false)}  onClick={() => setIsOpen(false)}></button>
            <div className="modal">
            {videoId && <div ref={iframeRef} className='modal-content'>
                <iframe src={`https://www.youtube.com/embed/${videoId}?&autoplay=1&loop=1&playlist=${videoId}&controls=0&rel=0&showinfo=0`} title="TON Web with mute final" allow='autoplay'></iframe>
            </div> }
            </div>
        </>
        )}
        <div className={`ai-video-bg-banner__loader ${props.loading ? 'loading' : null}`}>
            <Player className={'ai-video-bg-banner__loader-lottie'} ref={instance => playerRef} src={lottieAnimation} autoplay onEvent={handlePlayerEvents} keepLastFrame={true} style={{position: 'absolute', zIndex: 999, top: 0, left: 0, right: 0, bottom: 0, margin: 'auto', overflow: "hidden"}}></Player>
        </div>
    </div>
  )
}

export default VideoBgBanner