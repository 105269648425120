import React, { useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { PageData } from "../types/PageData";
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Navigation from '../components/Navigation';
import Heading from "../components/Heading";
import Paragraph from "../components/Paragraph";

function Error404() {
    const PageData = useLoaderData() as PageData
    const items = PageData.content.no_short_codes;
    const yoast:any = PageData.yoast_head_json;
    
    const imgRegex = /.*src="([^"]*)".*/;
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [items]);
  return (
    <div className='error-page'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : 'The Odd Number | '+PageData.title.rendered}</title>
        </Helmet>
        <Navigation />
        <div className="error-banner" style={{backgroundImage: `url(${items[0][0].replace(imgRegex, '$1')})`, backgroundSize: 'cover'}}>
        <div className="container">
                <div className="row">
                    <div className="col-12 col-md-8 col-lg-4">
                        {items[0][1][0] && <Heading Heading={items[0][1][0]} Tag={'h1'} />}
                        {items[0][2][2] && <Paragraph Paragraphs={items[0][2][2]} />}

                        <div className="links">
                            {items[0][2][0] && <Link className='btn btn__light' to={items[0][2][0].replace(aiButtonHref, '$1')}>{items[0][2][0].replace(aiButtonHref, '$2')}</Link>}

                            {items[0][3][0] && <Link className='btn btn__light' to={items[0][3][0].replace(aiButtonHref, '$1')}>{items[0][3][0].replace(aiButtonHref, '$2')}</Link>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Error404