import React, { useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { PageData } from "../types/PageData";
import ContentImage from '../components/ContentImage';
import ContentImageFullWidth from '../components/ContentImageFullWidth';
import Cta from '../components/Cta';
import NextSection from '../components/NextSection';
import VideoModal from '../components/VideoModal';
import SliderSingle from '../components/SliderSingle';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import TextBanner from '../components/TextBanner';
import AllTeamSlider from '../components/AllTeamSlider';
import {Helmet} from 'react-helmet';

import nextArrow from '../assets/images/icons/The-Odd-Number-Arrow-Right-Light.svg';
import prevArrow from '../assets/images/icons/The-Odd-Number-Arrow-Left-Light.svg';
import Navigation from '../components/Navigation';
import Heading from "../components/Heading";
import Paragraph from "../components/Paragraph";

function Culture() {
    const PageData = useLoaderData() as PageData
    const items = PageData.content.no_short_codes;
    const yoast:any = PageData.yoast_head_json;
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;
    const imgRegex = /.*src="([^"]*)".*/;

    const singleSliderSettings:any = {
        dots: false,
        infinite: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        autoplay: false,
        arrows: false,
        nextArrow: <img src={nextArrow} alt='' />,
        prevArrow: <img src={prevArrow} alt='' />,
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [items]);
  return (
    <div className='culture'>
        <Helmet>
            <title>{yoast !== undefined ? yoast.og_title : 'The Odd Number | '+PageData.title.rendered}</title>
        </Helmet>
        <Navigation />
        <VideoModal
            videoThumbnail={items[0][0]}
            video={items[0][1]+items[0][2]}
            placeholder={items[0][3][0].replace(imgRegex, '$1')}
        />

        <TextBanner 
            title={PageData.title.rendered}
            subtitle={items[1][0]}
            text={items[1][1][1]['paragraphs'][0]}
            background={items[1][1][0].replace(imgRegex, '$1')}
        />

        {/* <div className="container large-heading">
            <div className="row">
                <div className="col-12 col-md-8">
                    {items[2][0][0] && <Heading Heading={items[2][0][0]} Tag={'h2'} />}
                </div>
            </div>
        </div> */}

        <div className="component single-slider-wrapper" style={{backgroundImage: `url(${items[2][0].replace(imgRegex, '$1')})`}}>
            <div className="container">
                <Slider {...singleSliderSettings}>
                    <SliderSingle 
                        image={items[2][1].replace(imgRegex, '$1')}
                        title={items[2][2][0]}
                        subtitle={items[2][3][0]}
                        buttonHref={items[2][4][0]}
                    />
                
                    <SliderSingle 
                        image={items[2][5][0].replace(imgRegex, '$1')}
                        title={items[2][6][0]}
                        subtitle={items[2][7][0]}
                        buttonHref={items[2][8][0]}
                    />

                    <SliderSingle 
                        image={items[2][9][0].replace(imgRegex, '$1')}
                        title={items[2][10][0]}
                        subtitle={items[2][11][0]}
                        buttonHref={items[2][12][0]}
                    />
                </Slider>
            </div>
            <div className="container">
                <NextSection 
                    title='WHAT MAKES US DIFFERENT'
                    sectionAnchor='#the-team'
                    color='light'
                />
            </div>
        </div>

        <div id="the-team">
        <div className="container">
                <div className="row intro-content">
                    <div className="col-12 col-md-10 col-lg-8">
                        {items[3][0][0] && <Heading Heading={items[3][0][0]} Tag={'h2'} />}
                        {items[3][0][1] && <Paragraph Paragraphs={items[3][0][1]} />}
                    </div>
                </div>
            </div>
            <AllTeamSlider />
        </div>

        <div className="process">
            <ContentImage 
                layout='right text'
                image={items[4][0].replace(imgRegex, '$1')}
                title={items[4][1][0]}
                copy={items[4][2]}
            />

            <ContentImageFullWidth 
                layout='left text'
                image={items[5][0].replace(imgRegex, '$1')}
                title={items[5][1][0]}
                copy={items[5][2]}
            />

            <ContentImage 
                layout='right text'
                image={items[6][0].replace(imgRegex, '$1')}
                title={items[6][1][0]}
                copy={items[6][2]}
            />

            <ContentImage 
                layout='left text'
                image={items[7][0].replace(imgRegex, '$1')}
                title={''}
                copy={''}
            />
        </div>

        {/* <Cta 
            background={items[8][0].replace(imgRegex, '$1')}
            title={items[8][1][0]}
            copy={items[8][2][2]}
            buttonHref={items[8][2][0].replace(aiButtonHref, '$1')}
            buttonText={items[8][2][0].replace(aiButtonHref, '$2')}
        /> */}
    </div>
  )
}

export default Culture