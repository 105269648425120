import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Paragraph from "./Paragraph";
import Heading from "./Heading";

interface Props {
    image: string;
    title: string;
    subtitle: string;
    buttonHref: string;
    copy: string;
    layout: string;
    headingSize: string;
    number: string;
}

function ContentImageFullWidth({...props}) {
    const elementRef = useRef<HTMLDivElement>(null);
    const aiButtonHref = /.*href="([^"]*)".*title="([^"]*)".*/;

  let previousY = 0
  let previousRatio = 0

  const handleIntersection = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
    entries.forEach((entry) => {
      const currentY = entry.boundingClientRect.y;
      const currentRatio = entry.intersectionRatio;
      if(currentY < previousY){
        if(currentRatio> previousRatio && entry.isIntersecting){
          entry.target.classList.remove('animate');
        }
      } else if(currentY > previousY && entry.isIntersecting){
        if(currentRatio > previousRatio){
          entry.target.classList.add('animate');
        }
      }
    });
  };
    
    useEffect(() => {
        const options = {
          root: null,
          rootMargin: '0px',
          threshold: 0.5,
        };
          
        const observer = new IntersectionObserver(handleIntersection, options);
          
        if (elementRef.current) {
          observer.observe(elementRef.current);
        }
          
        return () => observer.disconnect();
    }, []);
  return (
    <div className='content-image full-width'>
        <div className="">
            <div className="d-flex"> 
                <div ref={elementRef} className={props.layout === 'right text' ? 'image left-image order-1 col-12 col-md-6' : 'image order-1 order-md-2 col-12 col-md-6'}>
                    {props.image && <img src={props.image} alt="" />}
                </div>
                <div className={props.layout === 'right text' ? 'text order-2 col-12 col-md-6' : 'text order-2 order-md-1 col-12 col-md-6'}>
                    {props.number && <div className="number">{props.number}</div>}

                    {props.title && <Heading className={props.headingSize === 'large' ? 'large-title' : 'title'} Heading={props.title} Tag={'h3'} />}

                    {props.subtitle && <div className="subtitle" dangerouslySetInnerHTML={{ __html: props.subtitle }} />}

                    {props.copy && <Paragraph className="copy" Paragraphs={props.copy} />}

                    {props.buttonHref && <Link className='btn btn__dark' to={props.buttonHref.replace(aiButtonHref, '$1')}>{props.buttonHref.replace(aiButtonHref, '$2')}</Link>}
                </div>
            </div>
        </div>
    </div>
  )
}

export default ContentImageFullWidth